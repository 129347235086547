import './MobileNavigationOffcanvas.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';
import React from 'react';

import { Button } from './Button';
import { t } from './i18n/i18n';
import { Icon } from './Icon';
import logo from './images/logo.svg';
import { SocialIcons } from './SocialIcons';

interface MobileNavigationOffcanvasProps extends b2x.OffcanvasProps {}

const MobileNavigationOffcanvas = (props: MobileNavigationOffcanvasProps) => {
  const menu = b2x.useMenu('MENU_HEADER_MOBILE', { populate: { content: true } });

  return (
    <b2x.Offcanvas {...props}>
      <div className="d-flex flex-column h-100 mobile-navigation-offcanvas">
        <Header />
        <div className="flex-grow-1 overflow-auto position-relative">
          <b2x.Drawers itemsClassName="px-4 py-1 border-bottom">
            <div className="d-flex flex-column h-100">
              <div className="flex-grow-1">{menu && <DrawerMenuItems item={menu} textBold textUppercase />}</div>
              <Footer />
            </div>
          </b2x.Drawers>
        </div>
      </div>
    </b2x.Offcanvas>
  );
};

const Header = () => {
  const { close } = b2x.useModalContext();

  return (
    <div>
      <div className="px-4 py-3 border-bottom hstack gap-4 justify-content-between">
        <b2x.router.Link onClick={close} to="/">
          <b2x.Image className="logo" fluid src={logo} />
        </b2x.router.Link>
        <Button iconEnd={{ name: 'close', size: 20 }} onClick={close} variant="blank" />
      </div>
    </div>
  );
};

const Footer = () => {
  const { info } = b2x.useAppContext();
  return (
    <div className="px-4 py-3 bg-gray-200">
      <div className="small fw-bold mb-2">{t('misc.customerService')}</div>
      <div>
        <Icon className="me-2" name="assistance" size={20} />
        <b2x.DeprecatedCta
          className="small text-reset text-decoration-none"
          cta={{ to: { href: `tel:${info?.customerCarePhoneNumber}` } }}
        >
          <span> {info?.customerCarePhoneNumber}</span>
        </b2x.DeprecatedCta>
      </div>
      <div className="hstack gap-2 my-2">
        <span className="small fw-medium pe-3">{t('misc.followUs')}</span>
        <SocialIcons iconTextColor="text-primary" />
      </div>
    </div>
  );
};

interface DrawerBackItemProps {
  label?: string;
}

const DrawerBackItem = ({ label = t('misc.back') }: DrawerBackItemProps) => {
  const { closeActiveDrawer } = b2x.useDrawersContext();

  return (
    <b2x.DrawerButtonItem className="drawer-back-button" onClick={closeActiveDrawer}>
      <b2x.DrawerItem
        center={{ center: label }}
        className="small fw-bold gap-3"
        height={35}
        justifyContent="start"
        start={{ center: <Icon name="arrow-left" /> }}
      />
    </b2x.DrawerButtonItem>
  );
};

interface DrawerMenuItemsProps {
  // depth?: number;
  item: b2x.MenuApiDto;
  textBold?: boolean;
  textUppercase?: boolean;
}

const DrawerMenuItems = ({ item, textBold, textUppercase }: DrawerMenuItemsProps) => {
  const isLastBranch = React.useMemo(
    () => item.children.every((child) => child.children.length === 0),
    [item.children]
  );

  return (
    <>
      {item.children.map((child) => {
        const hasChildren = child.children.length > 0;
        return hasChildren ? (
          <b2x.DrawerTriggerItem
            key={child.id}
            newDrawer={
              <Drawer backItemProps={{ label: child.label }}>
                <DrawerMenuItems item={child} textBold />
              </Drawer>
            }
          >
            <b2x.DrawerItem
              className={classnames(
                { 'text-uppercase': textUppercase },
                { 'fw-bold': textBold },
                { 'fw-medium': !textBold }
              )}
              end={{ center: <Icon name="arrow-right" /> }}
              height={isLastBranch ? 40 : 50}
              justifyContent="between"
              start={{ center: child.label }}
            />
          </b2x.DrawerTriggerItem>
        ) : (
          <b2x.DrawerLinkItem key={child.id} to={child.link}>
            <b2x.DrawerItem
              className={classnames(
                { 'text-uppercase': textUppercase },
                { 'fw-bold': textBold },
                { 'fw-medium': !textBold },
                child.cssClass
              )}
              height={isLastBranch ? 40 : 50}
              justifyContent="start"
              start={{ center: child.label }}
            />
          </b2x.DrawerLinkItem>
        );
      })}
      {item.link && (
        <b2x.DrawerLinkItem className="drawer-see-all-link" to={item.link}>
          <b2x.DrawerItem
            className="fw-bold"
            height={isLastBranch ? 40 : 50}
            justifyContent="start"
            start={{ center: `${t('misc.viewAll')} ${item.label}` }}
          />
        </b2x.DrawerLinkItem>
      )}
    </>
  );
};

interface DrawerProps {
  backItemProps?: DrawerBackItemProps;
  children: React.ReactNode;
}

const Drawer = ({ backItemProps, children }: DrawerProps) => {
  return (
    <div className="d-flex flex-column h-100 overflow-auto">
      <DrawerBackItem {...backItemProps} />
      <div className="bg-white flex-grow-1 overflow-auto">{children}</div>
    </div>
  );
};

export const useMobileNavigationOffcanvas = (props?: MobileNavigationOffcanvasProps) =>
  b2x.useStaticModal(MobileNavigationOffcanvas, 'offcanvas', props);
