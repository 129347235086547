import { b2x } from '@b2x/react/src';

import { Container } from '../Container';
import { GenericFormContentType } from '../contentTypes';
import { Page } from './Page';

export interface ForgotPasswordPageProps {}

export const ForgotPasswordPage = (props: ForgotPasswordPageProps) => {
  const content = b2x.useContent<GenericFormContentType>('FORGOT_PASSWORD_FORM_CONTENT');

  return (
    <Page>
      <Container>
        <b2x.Row className="justify-content-center">
          <b2x.Col size={{ lg: 5, xl: 4, xs: 12 }}>
            <div className="mb-4">
              <h1 className="text-center mb-3 h2">{b2x.formatHtml(content?.body.title)}</h1>
              <p>{b2x.formatHtml(content?.body.subTitle)} </p>
            </div>
            <b2x.ForgotPasswordFormHelper className="mb-4">
              {({ fieldsHelper }) => (
                <>
                  <b2x.FormGroup {...fieldsHelper.email.formGroup} label={undefined}>
                    <b2x.TextInput {...fieldsHelper.email.textInput} />
                  </b2x.FormGroup>
                  <div className="d-grid">
                    <b2x.Button {...fieldsHelper.buttons.submit} />
                  </div>
                </>
              )}
            </b2x.ForgotPasswordFormHelper>
          </b2x.Col>
        </b2x.Row>
      </Container>
    </Page>
  );
};
