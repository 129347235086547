import { b2x } from '@b2x/react/src';
import classnames from 'classnames';

export interface SectionProps {
  children?: React.ReactNode;
  className?: string;
  content?: string;
  headerBlockNoMargin?: boolean;
  noPaddingX?: boolean;
  noPaddingY?: boolean;
  textAlignment?: b2x.ResponsiveTextAlign;
  title?: string;
}
export const Section = ({
  children,
  className,
  content,
  headerBlockNoMargin,
  noPaddingX,
  textAlignment = 'center',
  title,
}: SectionProps) => {
  const currentBreakpoint = b2x.useBreakpoint();
  return (
    <section className={className}>
      <div className={classnames('container-xxl', { 'px-0': noPaddingX })}>
        {(title || content) && (
          <div
            className={classnames(b2x.buildTextAlignClassNames(textAlignment), {
              'mb-lg-5 mb-4': !headerBlockNoMargin,
              'px-3': noPaddingX,
            })}
          >
            {title && (
              <h3
                className={classnames(
                  'fw-bold mb-0',
                  { h3: b2x.untilBreakpoint('lg', currentBreakpoint) },
                  { h1: !b2x.untilBreakpoint('lg', currentBreakpoint) }
                )}
              >
                {b2x.formatHtml(title)}
              </h3>
            )}
            {content && <div className="mt-3">{b2x.formatHtml(content)}</div>}
          </div>
        )}
        {children && <div className="section-content">{children}</div>}
      </div>
    </section>
  );
};
