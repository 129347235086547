import { Resource } from '../i18n';

export const it: Resource = {
  misc: {
    storeName: 'Arexons',
  },
  product: {
    accordionFiles: {
      accordionLabel: {
        ARX_NORMATIVA_DETERGENTI: 'Normative detergenti',
        ARX_SCHEDA_SICUREZZA: 'Schede di sicurezza',
        ARX_SCHEDA_TECNICA: 'Schede tecniche',
      },
    },
    kitSection: {
      title: 'Contenuto del kit',
    },
    videoSection: {
      title: 'Guarda come utilizzare al meglio questo prodotto',
    },
  },
  productTile: {
    addToCart: 'Aggiungi',
    choseSkuVariant: 'Seleziona',
    measurement: 'Unità <strong>{{value}} {{measurementUnit}}</strong>',
    moreSkuAvailable: '+{{value}} varianti',
    notAvailable: 'Non disponibile',
    notAvailableMessage: 'Presto di nuovo disponibile',
    seeAllSkuOptions: 'Vedi tutte le opzioni di acquisto',
  },
  welcome: 'Benvenuto su store-arexons!',
};
