import { b2x } from '@b2x/react/src';

import { Container } from '../Container';
import { GenericFormContentType } from '../contentTypes';
import { Page } from './Page';

export interface ChangePasswordPageProps {}

export const ChangePasswordPage = (props: ChangePasswordPageProps) => {
  const content = b2x.useContent<GenericFormContentType>('CHANGE_PASSWORD_FORM_CONTENT');

  return (
    <Page>
      <Container>
        <b2x.Row className="justify-content-center">
          <b2x.Col size={{ lg: 5, xl: 4, xs: 12 }}>
            <div className="mb-4">
              <h1 className="h2 text-center mb-3">{b2x.formatHtml(content?.body.title)}</h1>
              <p>{b2x.formatHtml(content?.body.subTitle)} </p>
            </div>
            <b2x.ChangePasswordByTokenFormHelper className="mb-4">
              {({ fieldsHelper }) => (
                <>
                  <b2x.FormGroup {...fieldsHelper.newPassword.formGroup} label={undefined}>
                    <b2x.PasswordInput {...fieldsHelper.newPassword.passwordInput} />
                  </b2x.FormGroup>
                  <b2x.FormGroup {...fieldsHelper.confirmNewPassword.formGroup} label={undefined}>
                    <b2x.PasswordInput {...fieldsHelper.confirmNewPassword.passwordInput} />
                  </b2x.FormGroup>
                  <div className="d-grid">
                    <b2x.Button {...fieldsHelper.buttons.submit} />
                  </div>
                </>
              )}
            </b2x.ChangePasswordByTokenFormHelper>
          </b2x.Col>
        </b2x.Row>
      </Container>
    </Page>
  );
};
