import './Header.scss';

import { b2x } from '@b2x/react/src';
import React from 'react';

import { useAppContext, useAppStaticContext } from './AppContext';
import { Container } from './Container';
import { HeaderContentType } from './contentTypes';
import { DesktopHeader } from './DesktopHeader';
import { t } from './i18n/i18n';
import { Icon, isIconName } from './Icon';
import logo from './images/logo.svg';
import { MobileHeader } from './MobileHeader';
import { VerticalSlider } from './slider/VerticalSlider';

export interface HeaderProps {}

export const Header = (props: HeaderProps) => {
  const content = b2x.useContent<HeaderContentType>('HEADER_CONTENT');
  const mobileHeaderRef = React.useRef<HTMLDivElement>(null);
  const desktopHeaderFirstRowRef = React.useRef<HTMLDivElement>(null);
  const { headerCheckout } = useAppContext();

  const { setHeaderHeight } = useAppStaticContext();

  const { width } = b2x.useWindowSize();

  const recalculateHeaderHeight = React.useCallback(() => {
    const currentBreakpoint = b2x.getCurrentBreakpoint();
    if (['xs', 'sm', 'md'].includes(currentBreakpoint)) {
      // Mobile
      if (mobileHeaderRef.current) {
        setHeaderHeight(mobileHeaderRef.current.clientHeight);
      }
    } else {
      // Desktop
      if (desktopHeaderFirstRowRef.current) {
        setHeaderHeight(desktopHeaderFirstRowRef.current.clientHeight);
      }
    }
  }, [desktopHeaderFirstRowRef, mobileHeaderRef, setHeaderHeight]);

  React.useEffect(() => {
    recalculateHeaderHeight();
  }, [recalculateHeaderHeight, width]);

  const [activeCategory, setActiveCategory] = React.useState<b2x.MenuApiDto>();
  const [searchBoxVisible, setSearchBoxVisible] = React.useState<boolean>(false);

  return (
    <>
      {!headerCheckout ? (
        <>
          <TopBar content={content} />
          <MobileHeader
            innerRef={mobileHeaderRef}
            recalculateHeaderHeight={recalculateHeaderHeight}
            visibleUntil="xl"
          />
          <DesktopHeader
            activeCategory={activeCategory}
            content={content}
            firstRowRef={desktopHeaderFirstRowRef}
            recalculateHeaderHeight={recalculateHeaderHeight}
            searchBoxVisible={searchBoxVisible}
            setActiveCategory={setActiveCategory}
            setSearchBoxVisible={setSearchBoxVisible}
            visibleFrom="xl"
          />
        </>
      ) : (
        <b2x.HeaderCheckout
          logo={logo}
          logoSmall={logo}
          mobileFrom={'md'}
          recalculateHeaderHeight={recalculateHeaderHeight}
        />
      )}
    </>
  );
};

interface TopBarProps {
  content?: b2x.ContentApiDto<HeaderContentType>;
}

const TopBar = ({ content }: TopBarProps) => {
  const { setTopBarDesktopHeight, setTopBarRowRef } = useAppStaticContext();
  const { topBarRowRef } = useAppContext();

  React.useEffect(() => {
    setTopBarDesktopHeight(topBarRowRef.current?.clientHeight);
  }, [setTopBarDesktopHeight, topBarRowRef]);

  return (
    <div className={'top-bar bg-primary text-white small fw-medium'} ref={setTopBarRowRef}>
      <Container>
        <b2x.Row alignItems={'center'} gap={3}>
          <b2x.Col className="d-none d-xl-block" size={{ xl: 3, xs: 12 }}>
            {content?.body.topBar?.leftCta && (
              <div className="h-100 d-flex justify-content-start">
                <b2x.DeprecatedCta className="text-reset text-decoration-none" cta={content.body.topBar.leftCta}>
                  {content.body.topBar.leftCta.icon && isIconName(content.body.topBar.leftCta.icon) && (
                    <Icon className="me-1" name={content.body.topBar.leftCta.icon} size={16} />
                  )}
                  {content.body.topBar.leftCta.label}
                </b2x.DeprecatedCta>
              </div>
            )}
          </b2x.Col>
          <b2x.Col size={{ xl: 6, xs: 12 }}>
            {content?.body.topBar?.messagesSlider?.slides ? (
              <VerticalSlider {...content.body.topBar.messagesSlider} />
            ) : (
              <span className="extra-small">{t('welcome')}</span>
            )}
          </b2x.Col>
          <b2x.Col className="d-none d-xl-block" size={{ xl: 3, xs: 12 }}>
            {content?.body.topBar?.rightCta && (
              <div className="h-100 d-flex justify-content-end">
                <b2x.DeprecatedCta className="text-reset text-decoration-none" cta={content.body.topBar.rightCta}>
                  {content.body.topBar.rightCta.icon && isIconName(content.body.topBar.rightCta.icon) && (
                    <Icon className="me-1" name={content.body.topBar.rightCta.icon} size={16} />
                  )}
                  {content.body.topBar.rightCta.label}
                </b2x.DeprecatedCta>
              </div>
            )}
          </b2x.Col>
        </b2x.Row>
      </Container>
    </div>
  );
};
