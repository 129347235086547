import { b2x } from '@b2x/react/src';
import classNames from 'classnames';

import { ProductTile } from '../ProductTile';
import { SliderButton } from '../SliderButton';

export interface ProductsSliderProps extends Pick<b2x.SwiperProps, 'breakpoints'> {
  fullWidth?: boolean;
  noRelativeParent?: boolean;
  products: Array<b2x.ProductApiDto>;
}

export const ProductsSlider = ({ breakpoints, fullWidth, noRelativeParent, products }: ProductsSliderProps) => {
  return (
    <div className={classNames({ 'position-relative': !noRelativeParent })}>
      <b2x.EqualHeight>
        <b2x.SwiperContext>
          {({ navigationNextElRef, navigationPrevElRef, swiper }) => (
            <b2x.Div alignItems="center" display="flex">
              <b2x.Listing name="" products={products}>
                <b2x.Swiper
                  breakpoints={
                    breakpoints ?? {
                      lg: {
                        slidesPerView: fullWidth ? 4 : 3,
                      },
                      sm: {
                        slidesPerView: 2.4,
                      },
                      xs: {
                        slidesPerView: 1.2,
                      },
                    }
                  }
                  navigation={{ custom: true }}
                  slides={products.map((product) => (
                    <ProductTile key={product.id} product={product} />
                  ))}
                  slidesPerView={1.2}
                  spaceBetween={20}
                  style={{ flex: 1 }}
                />
              </b2x.Listing>
              <b2x.Div display={{ lg: 'block', xs: 'none' }}>
                <SliderButton direction="left" innerRef={navigationPrevElRef} size="large" />
              </b2x.Div>
              <b2x.Div display={{ lg: 'block', xs: 'none' }}>
                <SliderButton direction="right" innerRef={navigationNextElRef} size="large" />
              </b2x.Div>
            </b2x.Div>
          )}
        </b2x.SwiperContext>
      </b2x.EqualHeight>
    </div>
  );
};
