import './SearchPage.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';
import React from 'react';

import { useAppContext } from '../AppContext';
// import React from 'react';
import { Button } from '../Button';
import { Container } from '../Container';
import { SearchPageContentType } from '../contentTypes';
import { t } from '../i18n/i18n';
import { Icon } from '../Icon';
import { useMobileSearchFiltersOffcanvas } from '../MobileSearchFiltersOffcanvas';
import { PageHeader } from '../PageHeader';
import { ErrorSearchPage } from './ErrorSearchPage';
import { Page } from './Page';

export interface SearchPageProps {}

export const SearchPage = (props: SearchPageProps) => {
  const { page, searchResult } = b2x.useSearch<SearchPageContentType>({
    defaultPageSize: 12,
    pageOptions: {
      populate: {
        breadcrumb: {
          content: true,
        },
        children: true,
        content: true,
      },
    },
  });

  const [MobileSearchFiltersOffcanvas, showMobileSearchFiltersOffcanvas] = useMobileSearchFiltersOffcanvas({
    searchResult: searchResult,
  });

  const [mobileColumn] = React.useState<string>('multiple');

  const { getPage } = b2x.usePagesApi();

  const [filterSliderItems, setFilterSliderItems] = React.useState<Array<b2x.PageApiDto>>();

  React.useEffect(() => {
    if (page?.breadcrumb && page.breadcrumb.length > 1) {
      if (page.children && page.children.length > 0) {
        setFilterSliderItems(page.children);
      } else {
        const parent = page.breadcrumb[page.breadcrumb.length - 2];

        getPage(parent.id, { populate: { children: true } }).then((response) => {
          const parentChildren = response.data.children;

          if (parentChildren) {
            const indexOfElementToMove = parentChildren.findIndex((el) => el.code === page.code);
            const elementToMove = parentChildren[indexOfElementToMove];

            parentChildren.splice(indexOfElementToMove, 1);
            parentChildren.unshift(elementToMove);

            setFilterSliderItems(parentChildren);
          }
        });
      }
    } else {
      setFilterSliderItems(undefined);
    }
  }, [filterSliderItems, getPage, page?.breadcrumb, page?.children, page?.code]);

  const { headerHeight } = useAppContext();
  const currentBreakpoint = b2x.useBreakpoint();
  return (
    <>
      {searchResult?.itemsCount === 0 ? (
        <ErrorSearchPage />
      ) : (
        <Page className="search-page bg-gray-100" noPaddingBottom noPaddingTop>
          {MobileSearchFiltersOffcanvas}
          <PageHeader
            asset={page?.content?.body.pageHeader?.asset}
            className={page?.cssClass}
            shortDescription={page?.subTitle}
            title={page?.name ? page.name : 'Search'}
          />
          <section className="search-content">
            <Container>
              <div className="position-relative">
                <b2x.Row>
                  <b2x.Col className="px-0 px-lg-3" size={{ lg: 4, xl: 3, xs: 12 }}>
                    <div
                      className="search-filters-container mb-3 pe-lg-3"
                      style={
                        !b2x.untilBreakpoint('md', currentBreakpoint)
                          ? {
                              height: `calc(100vh - ${headerHeight}px)`,
                              top: headerHeight,
                            }
                          : undefined
                      }
                    >
                      <b2x.SearchFormHelper searchResult={searchResult} submitOnChange>
                        {({ fieldsHelper, formik }) => (
                          <>
                            <div className="d-grid d-sm-block d-lg-none">
                              <Button
                                className="show-mobile-search-filter w-100"
                                onClick={showMobileSearchFiltersOffcanvas}
                                type="button"
                                variant="link"
                              >
                                <div className="m-auto">
                                  <div className="me-3">
                                    <Icon className="me-2" name="filter-by" size={20} />
                                    {t('misc.filterAndOrderBy')}
                                  </div>
                                </div>
                              </Button>
                            </div>
                            <b2x.Div className="search-form-active-filter mb-4 px-3 px-lg-0">
                              {fieldsHelper.activeFilters.length > 0 && (
                                <b2x.Row gap={2}>
                                  {fieldsHelper.activeFilters.map((activeFilter, index) => (
                                    <b2x.Col key={activeFilter.filter.name + activeFilter.filter.id} size={'auto'}>
                                      {activeFilter.fromSimpleSearch && (
                                        <div className="py-2">{t('misc.youSearchedFor')}</div>
                                      )}
                                      <div>
                                        <Button
                                          className={classnames('btn-sm px-2 fw-bold gap-3 text-initial')}
                                          iconEnd={{ name: 'close', size: 14 }}
                                          justifyContent="between"
                                          label={activeFilter.filter.name}
                                          onClick={activeFilter.handleClick}
                                          type="button"
                                          variant="outline-gray-400"
                                        />
                                      </div>
                                    </b2x.Col>
                                  ))}
                                </b2x.Row>
                              )}
                            </b2x.Div>
                            <div className="mb-3">
                              <b2x.SearchFilters fieldsHelper={fieldsHelper} />
                            </div>
                          </>
                        )}
                      </b2x.SearchFormHelper>
                      {page?.description && (
                        <div className="small mb-5 d-none d-lg-block">{b2x.formatHtml(page.description)}</div>
                      )}
                    </div>
                  </b2x.Col>
                  <b2x.Col size={{ lg: 8, xl: 9, xs: 12 }}>
                    {searchResult?.itemsCount && (
                      <b2x.Row className="justify-content-between mb-3">
                        <b2x.Col size={'auto'}>
                          <div className="mb-2">{t('misc.searchItemResult', { count: searchResult.itemsCount })}</div>
                        </b2x.Col>
                        <b2x.Col size={'auto'}>
                          <SortingOptionsDropdown className="d-none d-lg-block mb-2" searchResult={searchResult} />
                        </b2x.Col>
                      </b2x.Row>
                    )}
                    {searchResult && searchResult.items && (
                      <b2x.Listing name="Search page" products={searchResult.items}>
                        <b2x.ProductsTiles
                          enableExtraInfo
                          gap={{ lg: 4, xs: 2 }}
                          products={searchResult.items.map((product) => ({ product: product }))}
                          productsPerRow={{
                            lg: 2,
                            md: 3,
                            sm: mobileColumn === 'multiple' ? 2 : 1,
                            xl: 3,
                            xs: mobileColumn === 'multiple' ? 2 : 1,
                            xxl: 3,
                          }}
                        />
                      </b2x.Listing>
                    )}
                    {searchResult && (
                      <b2x.Div className="my-3 my-lg-5" display="flex" justifyContent={{ lg: 'end', xs: 'center' }}>
                        <b2x.Pagination
                          currentPage={searchResult.pageNumber}
                          pageOffset={2}
                          showDots
                          singleStepNavigation
                          totalPages={searchResult.pagesCount}
                        />
                      </b2x.Div>
                    )}
                    {page?.description && (
                      <div className="small mb-5 d-block d-lg-none">{b2x.formatHtml(page.description)}</div>
                    )}
                  </b2x.Col>
                </b2x.Row>
              </div>
            </Container>
          </section>
        </Page>
      )}
    </>
  );
};

interface SortingOptionsDropdownProps {
  className?: string;
  searchResult?: b2x.SearchProductsApiDto;
}

const SortingOptionsDropdown = ({ className, searchResult }: SortingOptionsDropdownProps) => {
  const { sortingOptions } = b2x.useSortingOptions([
    { orderBy: 'PRICE', orderingType: 'ASC' },
    { orderBy: 'PRICE', orderingType: 'DESC' },
  ]);

  return (
    <>
      {searchResult && (
        <b2x.Dropdown
          buttonClassname="btn-sm px-2 gap-3 fw-bold text-lowercase"
          className={classnames('order-dropdown small', className)}
          iconStart={{ name: 'sort-by', size: 18 }}
          label={b2x.formatHtml(
            `${t('misc.orderBy')}: ${sortingOptions.find((sortingItem) => sortingItem.active)?.label}`
          )}
          variant="outline-gray-400"
        >
          {sortingOptions.map((sortingOption) => (
            <b2x.DropdownItem active={sortingOption.active} key={sortingOption.label} onClick={sortingOption.onClick}>
              <span>{sortingOption.label}</span>
            </b2x.DropdownItem>
          ))}
        </b2x.Dropdown>
      )}
    </>
  );
};
