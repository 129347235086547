import './MobileHeader.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';
import React from 'react';

import { useAppStaticContext } from './AppContext';
import { Button } from './Button';
import { Container } from './Container';
import { Icon } from './Icon';
import logoSmall from './images/logo-small.svg';
import { useMobileNavigationOffcanvas } from './MobileNavigationOffcanvas';
import { useMobileSimpleSearchOffcanvas } from './MobileSimpleSearchOffcanvas';

export interface MobileHeaderProps {
  innerRef: React.RefObject<HTMLDivElement>;
  recalculateHeaderHeight(): void;
  visibleUntil: b2x.Breakpoint;
}

export const MobileHeader = ({ innerRef, recalculateHeaderHeight, visibleUntil }: MobileHeaderProps) => {
  const { session } = b2x.useAppContext();
  const { getPagePath } = b2x.useAppStaticContext();

  const [MobileNavigationOffcanvas, showMobileNavigationOffcanvas] = useMobileNavigationOffcanvas();
  const [MobileSimpleSearchOffcanvas, showMobileSimpleSearchOffcanvas] = useMobileSimpleSearchOffcanvas();

  const { showAccountOffcanvas, showCartOffcanvas } = useAppStaticContext();

  return (
    <>
      {MobileNavigationOffcanvas}
      {MobileSimpleSearchOffcanvas}
      <b2x.Sticky offset={-1}>
        {({ isSticky }) => (
          <div className={classnames(`mobile-header bg-white shadow-sm d-block d-${visibleUntil}-none`)} ref={innerRef}>
            <Container>
              <b2x.Row className={classnames('align-items-center py-2')} gap={0}>
                <b2x.Col className="d-flex">
                  <div className="hstack gap-3">
                    <Button
                      className="text-secondary btn-menu"
                      iconEnd={{ name: 'menu', size: 25 }}
                      onClick={showMobileNavigationOffcanvas}
                      variant="blank"
                    />
                    <Button
                      className="text-secondary btn-search"
                      iconEnd={{ name: 'search', size: 25 }}
                      onClick={showMobileSimpleSearchOffcanvas}
                      variant="blank"
                    />
                  </div>
                </b2x.Col>
                <b2x.Col className="text-center">
                  <div className="mt-n2">
                    <b2x.router.Link to="/">
                      <b2x.Image onLoad={recalculateHeaderHeight} src={logoSmall} />
                    </b2x.router.Link>
                  </div>
                </b2x.Col>
                <b2x.Col className="d-flex justify-content-end">
                  <div className="hstack gap-3">
                    {session?.customer ? (
                      <b2x.router.Link className="btn-account lh-1 text-primary" to={getPagePath('SITE_ACCOUNT')}>
                        <Icon name="account" size={25} />
                      </b2x.router.Link>
                    ) : (
                      <Button
                        className="btn-account"
                        iconEnd={{ name: 'account', size: 25 }}
                        onClick={showAccountOffcanvas}
                        variant="blank"
                      />
                    )}
                    <Button className="btn-cart position-relative" onClick={showCartOffcanvas} variant="blank">
                      <Icon name={'cart'} size={25} />
                      {session?.cart?.itemsNumber !== undefined && session.cart.itemsNumber > 0 && (
                        <span className="position-absolute top-0 start-100 rounded-pill translate-middle badge text-primary">
                          {session.cart.itemsNumber}
                        </span>
                      )}
                    </Button>
                  </div>
                </b2x.Col>
              </b2x.Row>
            </Container>
          </div>
        )}
      </b2x.Sticky>
    </>
  );
};
