/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable func-style */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { b2x } from '@b2x/react/src';

export interface CivicProps {}

declare let CookieControl: any;

window.dataLayer = window.dataLayer || [];
function gtag(...args: Array<unknown>) {
  // eslint-disable-next-line prefer-rest-params
  window.dataLayer.push(arguments);
}

export const Civic = (props: CivicProps) => {
  const config = {
    acceptBehaviour: 'all',
    accessibility: {
      accessKey: 'C',
      disableSiteScrolling: false,
      highlightFocus: false,
      outline: true,
      overlay: true,
    },
    apiKey: 'ec0e4b7e870e2392d07465218c3b2988d9132deb',
    branding: {
      acceptBackground: '#1b4d8e',
      acceptText: '#ffffff',
      alertBackground: '#111125',
      alertText: '#fff',
      backgroundColor: '#eb0a2b',
      buttonIcon: null,
      buttonIconHeight: '64px',
      buttonIconWidth: '64px',
      closeBackground: '#FFF',
      closeText: '#111125',
      fontColor: '#fff',
      fontFamily: '&#039;Rubik&#039;,Arial,sans-serif',
      fontSize: '1em',
      fontSizeHeaders: '1.2em',
      fontSizeTitle: '1.5em',
      notifyBackgroundColor: '#313147',
      notifyFontColor: '#FFF',
      rejectBackground: '#111125',
      rejectText: '#ffffff',
      removeAbout: false,
      removeIcon: false,
      toggleBackground: '#111125',
      toggleColor: '#eb0a2b',
      toggleText: '#fff',
    },
    closeOnGlobalChange: true,
    closeStyle: 'icon',
    consentCookieExpiry: 90,
    encodeCookie: false,
    iabCMP: false,
    initialState: 'OPEN',
    layout: 'SLIDEOUT',
    locale: 'it_IT',
    locales: [
      {
        locale: 'it',
        mode: 'gdpr',
        optionalCookies: [
          {
            description:
              'Questi cookie ci aiutano a migliorare il nostro sito raccogliendo e analizzando informazioni su come il sito viene utilizzato.',
            label: 'Cookie di analitica (Google Analytics)',
          },
          {
            description:
              'Questi cookie ci aiutano a mostrarti messaggi pubblicitari mirati in linea con le tue preferenze manifestate durante la navigazione web e a misurare le campagne pubblicitarie.',
            label: 'Cookie di marketing (Facebook)',
          },
        ],
        text: {
          accept: 'Accetta tutti i cookie',
          acceptRecommended: 'Accetta i raccomandati',
          acceptSettings: 'Accetta tutti i cookie',
          intro:
            'Utilizziamo cookie tecnici per il funzionamento del sito. In caso di tuo consenso posizioneremo anche cookie di analitica e cookie di marketing. Per impostare le tue preferenze relative ai cookie sposta i cursori su “On” o “Off” e clicca “Salva e chiudi”. Puoi impostare velocemente l’installazione o meno di tutti i cookie di analitica e di marketing tramite i pulsanti “Accetta tutti i cookie” e “Rifiuta tutti i cookie”. Per ulteriori informazioni consulta la nostra Cookie Policy.',
          necessaryDescription:
            'Questi cookie permettono funzionalità essenziali come la gestione del network e la lingua del sito. Puoi disabilitare questi cookie tramite le impostazioni del browser.',
          necessaryTitle: 'Cookie tecnici',
          notifyDescription:
            'Usiamo i cookie per ottimizzare le funzionalità del sito e fornire la migliore esperienza possibile.',
          notifyTitle: 'Le tue scelte per i cookie su questo sito',
          off: 'Off',
          on: 'On',
          readMore: 'Scopri di più',
          reject: 'Rifiuta tutti i cookie',
          rejectSettings: 'Rifiuta tutti i cookie',
          settings: 'Impostazioni',
          showVendors: 'Mostra i fornitori all’interno di questa categoria',
          thirdPartyCookies: 'Questo fornitore potrebbe usare cookie di terza parte.',
          thirdPartyDescription:
            'Il consenso per i seguenti cookie non può essere revocato automaticamente. Segui i link qui sotto per revocarlo manualmente.',
          thirdPartyTitle: 'Alcuni cookie richiedono la tua attenzione',
          title: 'I nostri cookie',
        },
      },
    ],
    logConsent: true,
    mode: 'gdpr',
    necessaryCookies: ['CookieControl'],
    notifyDismissButton: true,
    notifyOnce: false,
    optionalCookies: [
      {
        cookies: ['_ga', '_ga*', '_gid', '_gat', '__utma', '__utmt', '__utmb', '__utmc', '__utmz', '__utmv'],
        description:
          'These cookies help us to improve our website by collecting and reporting information on how you use it.',
        label: 'Analytical Cookies',
        lawfulBasis: 'consent',
        name: 'analytics',
        onAccept: function () {
          gtag('consent', 'update', {
            analytics_storage: 'granted',
          });
        },
        onRevoke: function () {
          gtag('consent', 'update', {
            analytics_storage: 'denied',
          });
        },
        recommendedState: 'off',
        vendors: [
          {
            description: 'Google Analytics',
            name: 'Google',
            thirdPartyCookies: false,
            url: 'https://policies.google.com/technologies/types?hl=en-US',
          },
        ],
      },
    ],
    position: 'LEFT',
    product: 'PRO',
    rejectButton: true,
    sameSiteCookie: true,
    sameSiteValue: 'Strict',
    setInnerHTML: true,
    settingsStyle: 'button',
    statement: {
      description: 'Per maggiori informazioni consulta la nostra',
      name: 'Cookie Policy',
      url: 'shop.arexons.it/area-legale/cookie-policy',
    },
    subDomains: true,
    text: {
      accept: 'Accept',
      acceptRecommended: 'Accept Recommended Settings',
      acceptSettings: 'Accept all cookies',
      accessibilityAlert:
        'This site uses cookies to store information. Press accesskey C to learn more about your options.',
      closeLabel: 'Close',
      cornerButton: 'Set cookie preferences.',
      intro:
        'We use technical cookies to make our website work. In case you provide your consent, we will set also analytics cookies and marketing cookies. You may select your cookie preferences by using the ‘On’ or ‘Off’ sliders and then the ‘Save and close’ button. You may also quickly accept or reject all analytics and marketing cookies by means of the buttons ‘Accept all cookies’ and ‘Reject all cookies’.',
      landmark: 'Cookie preferences.',
      necessaryDescription:
        'These cookies enable core functionality such as network management, and language of the website. You may disable these cookies by changing your browser settings.',
      necessaryTitle: 'Technical Cookies',
      notifyDescription: 'We use cookies to optimise site functionality and give you the best possible experience.',
      notifyTitle: 'Your choice regarding cookies on this site',
      off: 'Off',
      on: 'On',
      readMore: 'Read more',
      reject: 'Reject',
      rejectSettings: 'Reject all cookies',
      settings: 'Cookie Preferences',
      showVendors: 'Show vendors within this category',
      thirdPartyCookies: 'This vendor may set third party cookies.',
      thirdPartyDescription:
        'Consent for the following cookies could not be automatically revoked. Please follow the link(s) below to opt out manually.',
      thirdPartyTitle: 'Warning: Some cookies require your attention',
      title: 'Our use of cookies',
    },
    theme: 'DARK',
    toggleType: 'slider',
  };

  b2x
    .addScript(
      { id: 'civic', src: 'https://cc.cdn.civiccomputing.com/9/cookieControl-9.x.min.js' },
      { appendToBody: true }
    )
    .then(() => {
      b2x.wait(300).then(() => {
        CookieControl.load(config);
      });
    });

  return <></>;
};
