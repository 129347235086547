import './CustomVariantRadio.scss';

import classnames from 'classnames';

import { SkuApiDto } from '../../storefront-api-js-client/src';

export interface CustomSkuRadioProps {
  forceSelected?: boolean;
  label?: string;
  sku: SkuApiDto;
}

export const CustomVariantRadio = ({ forceSelected, label, sku }: CustomSkuRadioProps) => {
  return (
    <div
      className={classnames(
        'custom-variant-radio rounded-pill px-3 py-1',
        { 'sku-not-available': sku.state !== 'AVAILABLE' },
        { selected: forceSelected }
      )}
    >
      {label || `${sku.measurement?.value} ${sku.measurement?.measurementUnit?.toLocaleLowerCase()}`}
    </div>
  );
};
