import { b2x } from '@b2x/react/src';

import { Container } from './Container';
import { AssetBannerContentType } from './contentTypes';

export interface AssetBannerProps extends AssetBannerContentType {}

export const AssetBanner = ({ asset, textBlock }: AssetBannerProps) => {
  const { alignment, content, cta, subTitle, textColor, title } = textBlock || {};

  return (
    <div className={`bg-secondary text-${textColor} overflow-hidden position-relative`}>
      <b2x.Div className="position-absolute h-100 w-100 d-none d-lg-block">
        <Container className="h-100">
          <b2x.Row className="h-100 w-100">
            <b2x.Col className={`order-${alignment === 'start' ? 1 : 2}`} display="flex" size={{ lg: 5, xs: 12 }}>
              <b2x.Div className="my-auto">
                {title && <b2x.H3 className="h1 mb-3">{title}</b2x.H3>}
                {subTitle && <b2x.H5 className="h3 mb-3">{subTitle}</b2x.H5>}
                {content && <b2x.P className="h5 ff-karla fw-normal mb-4 pb-3">{content}</b2x.P>}
                {cta && <b2x.CtaFromContent {...cta} />}
              </b2x.Div>
            </b2x.Col>
            <b2x.Col className={`order-${alignment === 'start' ? 2 : 1}`} size={{ lg: 7, xs: 12 }}></b2x.Col>
          </b2x.Row>
        </Container>
      </b2x.Div>
      <Container />
      <b2x.Row>
        <b2x.Col className="order-2" size={{ lg: 5, xs: 12 }}>
          <b2x.Div className="d-block d-lg-none px-2 mx-1 pt-4 mt-sm-2 mt-4 pb-4 mb-sm-4 mb-5 text-sm-start text-center">
            {title && <b2x.H3 className="h1 mb-3">{title}</b2x.H3>}
            {subTitle && <b2x.H5 className="h3 mb-3">{subTitle}</b2x.H5>}
            {content && <b2x.P className="h5 ff-karla fw-normal mb-4 pb-3">{content}</b2x.P>}
            {cta && <b2x.CtaFromContent {...cta} />}
          </b2x.Div>
        </b2x.Col>
        <b2x.Col className={`text-lg-end order-lg-${alignment === 'start' ? 2 : 1} order-1`} size={{ lg: 7, xs: 12 }}>
          <b2x.Asset {...asset} fluid />
        </b2x.Col>
      </b2x.Row>
    </div>
  );
};
