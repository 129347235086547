import { b2x } from '@b2x/react/src';

import { NewsletterPageContentType } from '../contentTypes';
import { NewsletterForm } from '../forms/NewsletterForm';

interface PopUpNewsletterModalProps extends b2x.ModalProps {}

const PopUpNewsletterModal = (modalProps: PopUpNewsletterModalProps) => {
  const content = b2x.useContent<NewsletterPageContentType>('NEWSLETTER_CONTENT');

  return (
    <b2x.Modal {...modalProps} className="popup-newsletter-modal">
      <div className="rounded-1">
        <b2x.ModalHeader title="" />
        <b2x.ModalBody className="px-3 ">
          <div className="text-center" style={{ marginTop: '-1rem' }}>
            <h3 className="fw-semi-bold  text-primary">{b2x.formatHtml(content?.body.title)}</h3>
            <div className="p-3 rounded-3 mt-3">
              <div className="my-3">{b2x.formatHtml(content?.body.content)}</div>
              <NewsletterForm ctaContainerClassName="my-3" emailFieldStyle="textInput" source="website-modal" />
            </div>
          </div>
        </b2x.ModalBody>
      </div>
    </b2x.Modal>
  );
};

export const usePopUpNewsletterModal = (props?: PopUpNewsletterModalProps) =>
  b2x.useStaticModal(PopUpNewsletterModal, 'modal', props);
