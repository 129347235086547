export const en = {
  misc: {
    storeName: 'Arexons',
  },
  product: {
    accordionFiles: {
      accordionLabel: {
        ARX_NORMATIVA_DETERGENTI: '',
        ARX_SCHEDA_SICUREZZA: '',
        ARX_SCHEDA_TECNICA: '',
      },
    },
    kitSection: {
      title: 'Kit Content',
    },
    videoSection: {
      title: 'See how to make the most of this product',
    },
  },
  productTile: {
    addToCart: 'Add',
    choseSkuVariant: 'Chose',
    measurement: 'Unit {{value}} {{measurementUnit}}',
    moreSkuAvailable: '+{{value}} variant',
    notAvailable: 'Not available',
    notAvailableMessage: 'In restock',
    seeAllSkuOptions: 'See all product options',
  },
  welcome: 'Welcome to Arexons',
};
