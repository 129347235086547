import './VerticalSlider.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';

import { VerticalSliderContentSection } from '../contentTypes';
import { Icon, isIconName } from '../Icon';

export interface VerticalSliderProps extends VerticalSliderContentSection {}

export const VerticalSlider = ({ slides, ...otherProps }: VerticalSliderProps) => {
  return (
    <b2x.SwiperContext>
      <div className="vertical-slider">
        <b2x.SwiperFromContent
          {...otherProps}
          // autoHeight
          direction="vertical"
          pagination
          slides={slides?.map(({ contentSectionId, cta }) => (
            <div className="d-flex justify-content-center align-items-center mb-2" key={cta?.label}>
              {cta?.label && (
                <b2x.DeprecatedCta
                  className={classnames('text-reset text-decoration-none d-flex align-items-center')}
                  cta={cta}
                >
                  {cta.icon && isIconName(cta.icon) && <Icon className="me-1" name={cta.icon} size={16} />}
                  {cta.label}
                </b2x.DeprecatedCta>
              )}
            </div>
          ))}
        />
      </div>
    </b2x.SwiperContext>
  );
};
