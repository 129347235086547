import './Icon.scss';

import { b2x } from '@b2x/react/src';

export const iconsNames = [
  'arrow-left',
  'arrow-right',
  'arrow-right-rounded',
  'assistance',
  'calendar',
  'cart',
  'close',
  'coupon',
  'credit-card',
  'download',
  'dropdown-close',
  'dropdown-open',
  'email',
  'error',
  'facebook-login',
  'facebook-rounded',
  'filter-by',
  'google-login',
  'hidden',
  'info',
  'instagram-rounded',
  'linkedin-rounded',
  'lock',
  'logout',
  'menu',
  'minus',
  'plus',
  'search',
  'shipping',
  'sort-by',
  'tiktok-rounded',
  'visible',
  'wishlist',
  'wishlist-full',
  'youtube-rounded',
  'account',
] as const;
export type IconName = (typeof iconsNames)[number];

export type IconSize = 12 | 14 | 16 | 18 | 20 | 24 | 25 | 30 | 35 | 48 | 72;

export interface IconProps extends b2x.IconProps<IconName, IconSize> {}

export const Icon = (props: IconProps) => <b2x.Icon {...props} />;

export const isIconName = (name?: string): name is IconName => b2x.isIconName(iconsNames, name);
