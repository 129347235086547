import { b2x } from '@b2x/react/src';

import { Icon } from './Icon';

export interface CustomCtaProps extends Omit<b2x.contentTypes.CtaContentSection, 'icon' | 'variant'> {
  className?: string;
  ctaProps?: b2x.CtaProps<string, string, number>;
}

export const CustomCta = ({ className, ctaProps, label, to }: CustomCtaProps) => {
  return (
    <>
      {label && (
        <b2x.Cta
          action={to?.action}
          button={{
            children: (
              <b2x.Div alignItems="center" className={className} display="flex" gap={3}>
                <b2x.P className="mb-0">{label}</b2x.P>
                {
                  <b2x.Div className="overflow-hidden rounded-circle position-relative">
                    <b2x.Div
                      className="bg-white z-1 rounded-circle position-absolute top-0 bottom-0 end-0 start-0 m-auto"
                      style={{ height: 28, width: 28 }}
                    />
                    <Icon className="text-primary z-2 position-relative" name={'arrow-right-rounded'} size={30} />
                  </b2x.Div>
                }
              </b2x.Div>
            ),
            className: 'text-white',
            variant: 'blank',
          }}
          code={to?.code}
          href={to?.href}
          link={{ ...ctaProps?.link }}
        />
      )}
    </>
  );
};
