import './HomePage.scss';

import { b2x } from '@b2x/react/src';
import React from 'react';

import { AssetBanner } from '../AssetBanner';
import { CardsRowsSection } from '../CardsRowsSection';
import { Container } from '../Container';
import { HomePageContentType } from '../contentTypes';
import { CustomCta } from '../CustomCta';
import { NewsletterForm } from '../forms/NewsletterForm';
import { Section } from '../Section';
import { ProductsSlider } from '../slider/ProductsSlider';
import { SliderA } from '../slider/SliderA';
import { SliderButton } from '../SliderButton';
import { Page } from './Page';
import { usePopUpNewsletterModal } from './PopupNewsletterModal';

export interface HomePageProps {}

export const HomePage = (props: HomePageProps) => {
  const { getPagePath } = b2x.useAppStaticContext();
  const page = b2x.usePage<HomePageContentType>({
    secondStep: {
      populate: {
        products: b2x.appConfig.api?.productTilePopulate,
      },
    },
  });

  const { pageReady } = b2x.useAppContext();

  const [PopupNewsLetterModal, showPopupNewsLetterModal] = usePopUpNewsletterModal({
    onClose: () => {
      const modalClosedTimes = (b2x.storage.getNumber('homePageNewsletterModalClosedTimes', true) ?? 0) + 1;
      b2x.storage.setNumber('homePageNewsletterModalClosedTimes', modalClosedTimes, true);
    },
  });

  React.useEffect(() => {
    const newsletterConsent = b2x.storage.getBoolean('newsletterConsent', true);
    if (newsletterConsent) {
      return;
    }

    const modalClosedTimes = b2x.storage.getNumber('homePageNewsletterModalClosedTimes', true) ?? 0;
    if (modalClosedTimes < 2 && pageReady) {
      b2x.wait(10000 * (modalClosedTimes + 1)).then(showPopupNewsLetterModal);
    }
  }, [pageReady, showPopupNewsLetterModal]);

  const productsTabs = b2x.useDirectory('HOME_PRODUCTS', {
    populate: { children: { associatedDirectories: true, products: b2x.appConfig.api?.productTilePopulate } },
  });

  const body = page?.content?.body;
  return (
    <Page className="home-page" greyBackground noPaddingBottom noPaddingTop thingsToLoadBeforePageReady={[page]}>
      {PopupNewsLetterModal}
      {body?.sliderSection && <SliderA {...body.sliderSection} />}
      {productsTabs && (
        <Section className="my-5 py-lg-5 py-3" textAlignment="start" title={body?.productsTabContentSection?.title}>
          <b2x.Div className="position-relative">
            <b2x.Tabs
              className="home-products-tabs gap-2"
              contentClassName="mt-4"
              tabStyle="pills"
              tabs={productsTabs.children.map((item) => ({
                children: item.products && (
                  <>
                    {item.associatedDirectories && item.associatedDirectories[0].code && (
                      <>
                        <b2x.Listing
                          name={`Home page - ${body?.productsTabContentSection?.title}`}
                          products={item.products}
                        >
                          <ProductsSlider fullWidth noRelativeParent products={item.products} />
                        </b2x.Listing>
                        <b2x.Div className="text-center mt-3 d-block d-lg-none">
                          <b2x.DeprecatedCta
                            className="btn text-black btn-link"
                            cta={{
                              to: {
                                href: getPagePath(item.associatedDirectories[0].code),
                              },
                            }}
                          >
                            {item.note}
                          </b2x.DeprecatedCta>
                        </b2x.Div>
                        <b2x.Div className="text-center d-none d-lg-block position-absolute top-0 end-0 py-1">
                          <CustomCta
                            className="text-secondary"
                            label={item.note}
                            to={{
                              href: getPagePath(item.associatedDirectories[0].code),
                            }}
                          />
                        </b2x.Div>
                      </>
                    )}
                  </>
                ),
                title: item.name?.toUpperCase() ?? '',
              }))}
            />
          </b2x.Div>
        </Section>
      )}
      {body?.cardsRowsSection && <CardsRowsSection {...body.cardsRowsSection} />}
      {body?.bannerSection && <AssetBanner {...body.bannerSection} />}
      {body?.brandsSection && (
        <Section className="brands-section overflow-hidden my-5 py-lg-5 py-3" title={body.brandsSection.title}>
          <b2x.Div>
            <b2x.SwiperContext>
              {({ navigationNextElRef, navigationPrevElRef }) => (
                <b2x.Div className="position-relative">
                  <b2x.Div className="position-absolute h-100 w-100" display={{ sm: 'block', xs: 'none' }}>
                    <SliderButton direction="left" innerRef={navigationPrevElRef} size="large" />
                    <SliderButton direction="right" innerRef={navigationNextElRef} size="large" />
                  </b2x.Div>
                  <b2x.Swiper
                    breakpoints={{
                      sm: {
                        slidesPerView: 5.5,
                      },
                      xl: {
                        slidesPerView: 7.5,
                      },
                      xs: {
                        slidesPerView: 4.5,
                      },
                    }}
                    className="overflow-visible"
                    loop
                    navigation={{ custom: true }}
                    slides={body.brandsSection?.brands?.map((brand) => (
                      <b2x.router.Link
                        key={brand.contentSectionId}
                        to={brand.directoryCode && getPagePath(brand.directoryCode)}
                      >
                        <b2x.Div className="brand-image">
                          <b2x.ImageFromContent {...brand.img} fluid />
                        </b2x.Div>
                      </b2x.router.Link>
                    ))}
                    watchSlidesProgress
                  />
                </b2x.Div>
              )}
            </b2x.SwiperContext>
          </b2x.Div>
        </Section>
      )}
      {body?.newsletterSection && (
        <b2x.Div className="newsletter-home-section mb-lg-5">
          <Container className="g-0 gx-lg-3">
            <div className={`newsletter-home-container bg-${body.newsletterSection.bgColor}`}>
              <b2x.Row className="g-0 gx-lg-3">
                <b2x.Col className="order-2 order-lg-1" size={{ lg: 6, xs: 12 }}>
                  <b2x.ImageFromContent {...body.newsletterSection.img} fluid />
                </b2x.Col>
                <b2x.Col className="order-1 order-lg-2 my-auto" display="block" size={{ lg: 6, xs: 12 }}>
                  <div className="pt-4 px-3 px-sm-4 pb-2">
                    {body.newsletterSection.title && (
                      <b2x.H3 className={`h2 fw-bold text-${body.newsletterSection.textColor} mb-0`}>
                        {b2x.formatHtml(body.newsletterSection.title)}
                      </b2x.H3>
                    )}
                    {body.newsletterSection.content && (
                      <b2x.P className={`h5 fw-normal ff-karla text-${body.newsletterSection.textColor} mb-0 mt-2`}>
                        {b2x.formatHtml(body.newsletterSection.content)}
                      </b2x.P>
                    )}
                    <NewsletterForm
                      className={`text-${body.newsletterSection.textColor} mt-3`}
                      emailFieldStyle="inputGroup"
                      source="website-footer"
                    />
                  </div>
                </b2x.Col>
              </b2x.Row>
            </div>
          </Container>
        </b2x.Div>
      )}
    </Page>
  );
};
