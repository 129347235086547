import { b2x } from '@b2x/react/src';

import { AppContext } from './AppContext';
import { Civic } from './Civic';
import { Footer } from './Footer';
import { Header } from './Header';
import { Icomoon } from './Icomoon';
import { ModalsAndOffcanvasses } from './ModalsAndOffcanvasses';
import { AccountHomePage } from './pages/AccountHomePage';
import { AccountPage } from './pages/AccountPage';
import { CartPage } from './pages/CartPage';
import { ChangePasswordPage } from './pages/ChangePasswordPage';
import { CheckoutPage } from './pages/CheckoutPage';
import { Error404Page } from './pages/Error404Page';
import { ForgotPasswordPage } from './pages/ForgotPasswordPage';
import { HomePage } from './pages/HomePage';
import { LandingTemplateAPage } from './pages/LandingTemplateAPage';
import { NewsletterSubscribePage } from './pages/NewsletterSubscribePage';
import { ProductPage } from './pages/ProductPage';
import { RegistrationPage } from './pages/RegistrationPage';
import { SearchPage } from './pages/SearchPage';
import { ServicePage } from './pages/ServicePage';
import { TemplatePage } from './pages/TemplatePage';
import { ThankYouPage } from './pages/ThankYouPage';
import { ThankYouPageOrderFailed } from './pages/ThankYouPageOrderFailed';
import { WhoisPage } from './pages/WhoisPage';

export const App = () => {
  return (
    <AppContext>
      {(appContext, appStaticContext) => (
        <b2x.App
          onAddToCartSuccess={appStaticContext.showCartOffcanvas}
          onAddToWishlistAsGuestButtonClick={appStaticContext.showAccountOffcanvas}
        >
          <ModalsAndOffcanvasses />
          <Icomoon />
          <div className="d-flex flex-column flex-grow-1">
            <Header />
            <div className="flex-grow-1">
              <b2x.router.MainRouter
                codeMappings={{
                  SITEMAP: <HomePage />,
                  SITE_ACCOUNT: <AccountHomePage />,
                  SITE_CCN_AREA: { component: <AccountPage />, separateRouter: true },
                  SITE_CUSTOMER_CARE: { component: <ServicePage />, separateRouter: true },
                  SITE_LEGAL_AREA: { component: <ServicePage />, separateRouter: true },
                  SITE_TEMPLATE: <TemplatePage />,
                }}
                error404Page={<Error404Page />}
                fixedMappings={{
                  cart: <CartPage />,
                  'change-password': <ChangePasswordPage />,
                  checkout: <CheckoutPage />,
                  'forgot-password': <ForgotPasswordPage />,
                  newsletter: <NewsletterSubscribePage />,
                  'order-ko': <ThankYouPageOrderFailed />,
                  'order-ok': <ThankYouPage />,
                  register: <RegistrationPage />,
                  search: <SearchPage />,
                  template: <TemplatePage />,
                  whois: <WhoisPage />,
                }}
                templateMappings={{
                  LANDING_TEMPLATE_A_PAGE_CONTENT_TYPE: <LandingTemplateAPage />,
                }}
                typeMappings={{
                  CATALOG: <SearchPage />,
                  LISTING: <SearchPage />,
                  PRODUCT: (
                    <b2x.ProductTypeSwitch
                      assembled={<ProductPage />}
                      default={<ProductPage />}
                      kit={<ProductPage />}
                    />
                  ),
                }}
              />
            </div>
            <Footer />
            <div id="products-tiles-container"></div>
          </div>
          <Civic />
        </b2x.App>
      )}
    </AppContext>
  );
};
